import { Navbar, Tickets, Separator } from '../components';
import { Footer } from '../containers';

export function Shows() {
  return (
    <>
    <Navbar />
    <Separator />
    <Tickets />
    <Separator />
    <Footer />
    </>
  )
}

export default Shows;
