
const Story = () => {

  return (
    <div className="our-story-container section__padding container">
        <h3>Di cosa parla Divino?</h3>
        <p>
            <i>Divino</i> è un <b>musical</b> che parla di perdono, di sogni, di amore incondizionato, liberamente ma fedelmente
            ispirato alla parabola evangelica del Figliol Prodigo. L'intreccio racconta di una ragazza, che cercherà di
            capire quale sia il suo posto nel mondo; l'altro protagonista, suo padre, lascerà la propria figlia libera di
            errare, tra vicoli ciechi e false speranze, covando in cuor proprio l'ardente desiderio di riabbracciarla.
            Divino è 16 canzoni e oltre 50 pagine di copione, originali, scritte da noi.
        </p>
        <br />
        <br />
        <h3>Come è nato Divino?</h3>
        <p>
            La scrittura della storia, dalle sue idee fondanti al copione vero e proprio, è stata interamente seguita da
            giovani della Parrocchia di Formigine. Questi ragazzi, di non più di 26 anni di età, sono cresciuti in un
            contesto dove i musical erano parte fondante dell'attività pastorale: da Milioni Di Sogni ad Il Sorriso Di Dio,
            passando per il recente <a href="https://www.youtube.com/watch?v=tCgvs_yK5pU" target="_blank" rel="noopener noreferrer">Fino Alla Fine Del Mondo</a>. La passione per la musica ed il teatro si è ancora una
            volta fusa con il desiderio di creare un progetto aggregativo per i ragazzi, della parrocchia e non solo.
        </p>
        <br />
        <br />
        <h3>Quali obiettivi si pone Divino?</h3>
        <p>Il progetto è riassumibile, sotto vari aspetti, in due parole: <b>sana ambizione.</b></p>
        <p>
            La sana ambizione di riuscire a raggruppare giovani delle scuole superiori, in un contesto dove possano
            esprimere i propri talenti e scoprirne di nuovi. La natura stessa del musical, come opera artistica,
            sottintende l'esistenza di un gran numero di gruppi di lavoro dove un giovane può trovare il proprio spazio:
            scenografia, corpo di ballo, attori, comunicazione, band, coro, costumisti, tecnici audio e luci, contenuti audio e video.
        </p>
        <p>
            Abbiamo anche la sana ambizione di creare qualcosa di bello, visivamente e musicalmente: un prodotto "di qualità"
            che il pubblico possa apprezzare anche dal punto di vista artistico, oltre che umano, formativo e spirituale.
        </p>
        <p>
            C'è anche la sana ambizione di essere vera occasione di aggregazione per i ragazzi, affinché possano essere
            parte importante di un progetto più grande, in cui collaborare per raggiungere il comune obiettivo del palcoscenico.
        </p>
        <p>
            Abbiamo, infine, la sana ambizione di parlare alle persone: dai giovani che parteciperanno a tutti coloro che
            verranno a vederlo, fino ai "meno giovani" che ci accompagneranno, il musical è stato scritto per essere
            vissuto in prima persona. I personaggi hanno sogni, speranze, storie difficili e fragili certezze, sentono il peso
            delle responsabilità da un lato, e del futuro dall'altro.
        </p>
        <p>Sono, <i>tutti</i>, alla ricerca di qualcuno che li faccia sentire importanti, degni di stima ed amore.</p>
    </div>
  )
}

export default Story
