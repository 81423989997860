import { Navbar, Story, Separator } from '../components';
import { Footer } from '../containers';

export function ChiSiamo() {
    return (
      <>
      <Navbar />
      <Separator />
      <Story />
      <Separator />
      <Footer />
      </>
    )
}

export default ChiSiamo;
